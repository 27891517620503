/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { takeUntil, first, startWith } from 'rxjs/operators';

import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsListComponent } from '@documents/components';
import { DocumentFormService, DocumentService } from '@documents/services';
import { PermissionService } from '@shared/services/permission.service';

import {
  InstrumentFormService,
  CharacteristicFormService,
  InstrumentModalsService,
  EventsDataDisplayService,
  GlobalNoteFormService,
  InstrumentAddendumFormService,
  IssuanceFormService,
  RoleDataFormService,
  ListingFormService,
  EventsDataFormService,
  TrancheFormService,
  TrancheDisplayService,
  IssuanceDisplayService
} from '../../services';
import { InstrumentModel } from '@instruments/models/instrument.model';
import { Forms } from '@shared/utils';
import { Subject, combineLatest } from 'rxjs';
import { InstrumentRoleMatrixService } from '@instruments/services/instrument-role-matrix.service';
import { DocumentFileModel, DocumentTypeInjectionToken } from '@documents/models';
import { ProgrammeModel } from '@shared/models/programme.model';
import { TrancheModel } from '@instruments/models';
import { IpaTypeModel } from '@shared/models';
import { DocumentationReceptionFormService } from '@instruments/services/forms/documentation-reception.form.service';
import { CommonDepositoryFormService } from '@instruments/services/forms/common-depository.form.service';
import { ManagementCommonDepositoryFormService } from '@instruments/services/forms/managmement-common-depository.form.service';
import { PermanentMonitoringFormService } from '@instruments/services/forms/permanent-monitoring.form.service';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { InstrumentService } from '@shared/services/instrument.service';
import { routeToTranche } from '@instruments/routes';

@Component({
  selector: 'app-instrument',
  templateUrl: './instrument.component.html',
  providers: [
    InstrumentFormService,
    CharacteristicFormService,
    EventsDataFormService,
    GlobalNoteFormService,
    InstrumentAddendumFormService,
    IssuanceFormService,
    ListingFormService,
    InstrumentModalsService,
    RoleDataFormService,
    IssuanceDisplayService,
    InstrumentRoleMatrixService,
    EventsDataDisplayService,
    TrancheFormService,
    TrancheDisplayService,
    { provide: DocumentTypeInjectionToken, useValue: 'instrument' },
    DocumentService,
    DocumentFormService,
    DocumentationReceptionFormService,
    CommonDepositoryFormService,
    ManagementCommonDepositoryFormService,
    PermanentMonitoringFormService
  ]
})
export class InstrumentComponent implements OnInit, OnDestroy {
  @ViewChild(DocumentsListComponent) documentsListComponent!: DocumentsListComponent;

  public activeTab = 'documents';
  public documentsCollapsed = false;
  public programme: ProgrammeModel | null = null;
  public canEditInstrument = false;
  public cameFromProgramme = false;
  public documents: DocumentFileModel[] = [];
  public isLoadingDocument = false;
  public readonly shutdown$ = new Subject<void>();
  public currentTranche: TrancheModel | null = null;
  public instrumentStatus: IpaTypeModel | null | undefined;
  public isUploadClicked = false;
  public instrumentId: any;
  public docList: any;
  constructor(
    private readonly router: Router,
    public readonly instrumentFormService: InstrumentFormService,
    private readonly characteristicFormService: CharacteristicFormService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly permissionService: PermissionService,
    private readonly documentFormService: DocumentFormService,
    private readonly documentService: DocumentService,
    private readonly instrumentService: InstrumentService,
    private readonly modalsService: InstrumentModalsService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state) {
      this.activeTab = state.activeTab;
    }
  }
  public toggleDocuments(): void {
    this.documentsCollapsed = !this.documentsCollapsed;
  }
  public get instrument(): Nullable<InstrumentModel> {
    return this.instrumentFormService.rawValue();
  }

  public get isEditMode(): boolean {
    return this.instrumentFormService.formMode === 'edit';
  }

  public get isAddMode(): boolean {
    return this.instrumentFormService.formMode === 'add';
  }

  public get isConsultMode(): boolean {
    return this.instrumentFormService.formMode === 'consult';
  }

  public get canCloseInstrument(): boolean {
    return this.isConsultMode && this.canEditInstrument && this.instrumentStatus?.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.LIVE;
  }
  private async loadDocuments(instrumentId: number) {
    this.isLoadingDocument = true;
    await this.documentService
      .searchDocuments(instrumentId)
      .pipe(first())
      .pipe(takeUntil(this.shutdown$))
      .subscribe(res => {
        this.documents = res;
        this.docList = res;
        this.changeDetectorRef.detectChanges();
        this.isLoadingDocument = false;
      });
  }

  getupdatedoc(val: any) {
    if (val == true) {
      this.loadDocuments(this.instrumentId);
    }
  }
  private initProgramme(programme: ProgrammeModel) {
    this.programme = programme;
    this.characteristicFormService.patch('internalProgrammeNumber', programme.id);
    this.characteristicFormService.lockFields('internalProgrammeNumber');
  }

  ngOnInit(): void {
    this.instrumentFormService.subscribeTrancheModification(this.shutdown$);

    this.instrumentFormService.currentTranche$.pipe(takeUntil(this.shutdown$)).subscribe(tranche => (this.currentTranche = tranche));

    combineLatest([this.activatedRoute.data.pipe(startWith(...[])), this.activatedRoute.params.pipe(startWith(...[]))])
      .pipe(takeUntil(this.shutdown$))
      .subscribe(([routeData, routeParams]) => {
        this.instrumentStatus = this.instrument.status;
        this.instrumentFormService
          .valueChanges('status')
          .pipe(takeUntil(this.shutdown$))
          .subscribe(q => {
            this.instrumentStatus = q;
          });

        const values = routeData['instrument'] as [InstrumentModel | null, ProgrammeModel | null];

        const programme = values ? values[1] : null;
        const instrument = values ? values[0] : null;
        if (programme) {
          this.initProgramme(programme);
        }
        const pageType = Forms.isFormMode(routeParams.pageType) ? routeParams.pageType : 'consult';

        if (pageType !== 'add') {
          this.instrumentFormService.setInitialValue(instrument);
          this.instrumentFormService.setTranchesInformations(instrument?.tranches || [], this.currentTranche?.trancheNumber || 1);
        } else {
          this.instrumentFormService.setTranchesInformations([], 1);

          this.instrumentFormService.clearTrancheInformations();
        }

        this.instrumentFormService.setFormMode(pageType);
        this.documentFormService.setFormMode(pageType);

        if (instrument?.id && pageType === 'consult') {
          this.instrumentId = instrument.id;
          this.loadDocuments(this.instrumentId);
        }

        this.cameFromProgramme = 'internalProgrammeNumber' in routeParams;

        if (routeParams.icsdProgrammeNumber) {
          this.characteristicFormService.patch('icsdProgrammeNumber', routeParams.icsdProgrammeNumber);
          this.characteristicFormService.control('icsdProgrammeNumber').disable();
        }

        if (routeParams.leiCode) {
          this.characteristicFormService.loadLeiInformation(routeParams.leiCode, false);
          this.characteristicFormService.control('issuerLeiCode').disable();
        }
      });

    this.permissionService.canEditInstrument$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canEditInstrument: boolean) => {
        this.canEditInstrument = canEditInstrument;
      }
    });
  }
  handleUpload(value: boolean) {
    this.isUploadClicked = value;
  }
  handleModify(value: boolean) {
    this.isUploadClicked = value;
    this.documents = this.docList;
    this.changeDetectorRef.detectChanges();
  }
  public onCloseInstrument(): void {
    const modal = this.modalsService.openConfirmCloseInstrument();
    modal.result.then(res => {
      if (res !== 'confirm') {
        return;
      }
      this.instrumentService
        .updateStatus('CLOSE', { ipaCode: this.instrument.id })
        .pipe(first())
        .subscribe(_ => {
          if (this.instrument?.id) {
            this.instrumentService
              .getInstrumentById(this.instrument.id)
              .pipe(first())
              .subscribe(i => {
                this.instrumentFormService.setInitialValue(i);
                this.router.navigate(routeToTranche(this.instrument?.id, 1, 'consult'));
              });
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
