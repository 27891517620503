<div *ngIf="showTitle" class="d-flex justify-content-between align-items-center">
  <h5 class="mb-4" translate>programmes.programme.forms.documentation</h5>
  <app-upload-documents #uploadDocuments="uploadDocuments" (isUpload)="getIsUpload($event)"></app-upload-documents>
</div>

<div *ngIf="isLoading; else list" class="loading-spinner small text-center"><span>⭮</span></div>
<ng-template #list>
  <div class="d-flex flex-row justify-content-between">
    <button class="btn btn-flat-secondary" style="display: flex"
      (click)="sortChanged('switchDateDown', true, ['date'])">Date
      <ng-container *ngIf="switchs.switchDateDown != null">
        <em class="icon ms-1" *ngIf="switchs.switchDateDown">arrow_downward</em>
        <em class="icon ms-1" *ngIf="!switchs.switchDateDown">arrow_upward</em>
      </ng-container>
    </button>
    <button class="btn btn-flat-secondary"
      (click)="sortChanged('switchNameDown', false, ['metadata', 'documentName'])">Name
      <ng-container *ngIf="switchs.switchNameDown != null">
        <em class="icon ms-1" *ngIf="switchs.switchNameDown">arrow_downward</em>
        <em class="icon ms-1" *ngIf="!switchs.switchNameDown">arrow_upward</em>
      </ng-container>
    </button>
    <button class="btn btn-flat-secondary"
      (click)="sortChanged('switchTypeDown', false, ['metadata', 'documentType'])">Type
      <ng-container *ngIf="switchs.switchTypeDown != null">
        <em class="icon ms-1" *ngIf="switchs.switchTypeDown">arrow_downward</em>
        <em class="icon ms-1" *ngIf="!switchs.switchTypeDown">arrow_upward</em>
      </ng-container>
    </button>
    <button class="btn btn-flat-secondary"
      (click)="sortChanged('switchConfidentialityLevelDown', false, ['confidentialityLevel'])">Confidentiality level
      <ng-container *ngIf="switchs.switchConfidentialityLevelDown != null">
        <em class="icon ms-1" *ngIf="switchs.switchConfidentialityLevelDown">arrow_downward</em>
        <em class="icon ms-1" *ngIf="!switchs.switchConfidentialityLevelDown">arrow_upward</em>
      </ng-container>
    </button>
  </div>
  <hr class="my-0 mt-2" />
  <ng-container *ngIf="documents?.length">
    <div class="row mt-4" *ngIf="isEditMode">
      <div class="form-group col-md-12">
        <app-document-forms-list #documentFormsList [compact]="true"></app-document-forms-list>
      </div>
    </div>
    <div class="mb-2 ms-2" class="d-flex flex-row justify-content-between">
      <input *ngIf="canDeleteFile" type="checkbox"
        style="align-self: center; width: 1rem; height: 1rem; margin-bottom: 0.6rem; margin-top: 0.6rem"
        (click)="selectAllDocuments()" [checked]="selectedDocuments.length === documents.length" />
      <div>
        <button *ngIf="canDeleteFile" [hidden]="selectedDocuments.length === 0" class="btn btn-flat-secondary"><em class="icon"
            style="font-size: x-large" (click)="deleteSelectedDocuments()"
            (keyup.enter)="deleteSelectedDocuments()">delete</em></button>
      </div>
    </div>
    <ng-container *ngFor="let document of documents; let i = index">
      <div class="bg-lvl2 py-3 d-flex align-items-center mt-3">
        <input *ngIf="canDeleteFile" class="ms-2" type="checkbox" (click)="selectDocument(document)"
          [checked]="this.selectedDocuments.includes(document)" />
        <div class="ps-3 flex-grow-1 text-info">
          <div>
            <span class="fw-medium" [title]="document?.metadata?.documentName"
              style="text-overflow: ellipsis; overflow: hidden">{{
            document?.metadata?.documentName
          }}</span><span class="text-end">.{{ document?.metadata?.contentFormat }}</span><br />
            <span class="text-end">{{ document?.metadata?.documentType }} |
              {{ document?.confidentialityLevel }}</span>
            <br />
            <span class="text-end">{{ document?.date | date: 'd/MM/y HH:mm:ss.SSS':'UTC':'fr' }}</span>
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-lg btn-flat-light" (click)="openDocument(document)">
            <em class="icon">visibility</em>
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-lg btn-flat-light" (click)="downloadDocument(document)">
            <em class="icon">cloud_download</em>
          </button>
        </div>
        <div *ngIf="canDeleteFile">
          <button class="btn btn-lg btn-flat-light" (click)="deleteDocument(document)">
            <em class="icon">delete</em>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!documents || documents?.length === 0">
    <div class="row mt-4" *ngIf="isEditMode">
      <div class="form-group col-md-12">
        <app-document-forms-list [compact]="true" #documentFormsList></app-document-forms-list>
      </div>
    </div>
    <div class="bg-lvl2 py-4 d-flex align-items-center">
      <div class="ps-3 flex-grow-1 text-info">
        <div>
          <span class="fw-medium">No files found</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>