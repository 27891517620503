import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDocumentsComponent } from './upload-documents.component';

@NgModule({
  declarations: [UploadDocumentsComponent],
  exports: [UploadDocumentsComponent],
  imports: [CommonModule]
})
export class UploadDocumentsModule {}
