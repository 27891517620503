import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentFormService } from '@documents/services';
import { InstrumentModel } from '@instruments/models';
import { InstrumentFormService } from '@instruments/services';
import { UploadFileSharedService } from '@instruments/services/uploadFile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  exportAs: 'uploadDocuments'
})
export class UploadDocumentsComponent {
  public disableUpload = true;
  @Output() public isUpload = new EventEmitter<boolean>();
  constructor(
    private readonly router: Router,
    private readonly documentFormService: DocumentFormService,
    private readonly instrumentFormService: InstrumentFormService,
    private readonly uploadFileSharedService: UploadFileSharedService,
    private readonly toastManagerService: ToastManagerService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit() {
    this.uploadFileSharedService.currentData.subscribe(isFileUpload => {
      this.disableUpload = isFileUpload ? false : true;
    });
  }
  public get isAddMode(): boolean {
    return this.instrumentFormService.formMode === 'add';
  }

  public get isConsultMode(): boolean {
    return this.instrumentFormService.formMode === 'consult';
  }
  public get instrumentServices(): Nullable<InstrumentModel> {
    return this.instrumentFormService.rawValue();
  }
  async onSaveClick() {
    this.instrumentFormService.updateValueAndValidity({ emitEvent: false });
    const instrument: any = this.instrumentFormService.value();
    const id = instrument.id;
    await this.documentFormService.uploadFiles(id, instrument);
    this.isUpload.emit(true);
    this.toastManagerService.toastSuccess('toasts.documents.upload.title', 'toasts.documents.upload.message');
    this.disableUpload = true;
    //this.router.navigate(routeToTranche(id, instrument.selectedTrancheNumber, 'consult'));
  }
}
