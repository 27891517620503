import { Injectable, Inject } from '@angular/core';
import { ArrayFormService } from '@shared/services/forms/array.form.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IdTech, DocumentTypeInjectionToken, FileUploadModel } from '@documents/models';
import { Observable, from, EMPTY } from 'rxjs';
import { HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { filter, map, take, catchError, mergeMap } from 'rxjs/operators';
import { DocumentResourceService } from '@apis/backend/instruments';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';

@Injectable()
export class DocumentFormService extends ArrayFormService<FileUploadModel> {
  constructor(fb: UntypedFormBuilder, @Inject(DocumentTypeInjectionToken) private readonly documentType: any, private readonly documentResourceService: DocumentResourceService, private readonly toastManagerService: ToastManagerService) {
    super(fb, () => ({
      confidentialityLevel: fb.control('C1', Validators.required),
      documentName: fb.control(null, Validators.required),
      file: fb.control(null, Validators.required),
      modelId: fb.control(null, Validators.required)
    }));
  }

  private uploadFile(file: FileUploadModel, idTechParent: IdTech, dataParent: unknown): { progress: Observable<number>; done: Observable<string> } {
    let instrumentId = '';
    if (this.documentType == 'instrument' && (idTechParent != null || idTechParent != undefined)) {
      instrumentId = idTechParent.toString();
    }
    const baseObs = this.documentResourceService
      .addDocument(file.confidentialityLevel, file.modelId, instrumentId, JSON.stringify({ ...(dataParent as any), ...{ idTech: idTechParent }, ...{ typeTech: this.documentType } }), file.file, file.documentName, 'events', true)
      .pipe(
        catchError(() => {
          console.error('le fichier : %s a rencontré une erreur', file.file.name);
          this.toastManagerService.toastError('toasts.documents.uploadError.title', 'toasts.documents.uploadError.message', { documentName: file.documentName }, undefined, false);
          return EMPTY;
        })
      );

    const isProgressEvent = <T>(event: HttpEvent<T>): event is HttpProgressEvent => {
      return event.type === HttpEventType.UploadProgress;
    };
    const isResponseEvent = <T>(event: HttpEvent<T>): event is HttpResponse<T> => {
      return event.type === HttpEventType.Response;
    };

    const fileSize = file.file.size;
    const progress = baseObs.pipe(
      filter(isProgressEvent),
      map(event => Math.round((100 * event.loaded) / (event.total || fileSize)))
    );
    const done = baseObs.pipe(
      filter(isResponseEvent),
      map(res => res?.body?.idDocument ?? '')
    );

    return {
      progress,
      done
    };
  }

  public uploadFiles(idTechParent: IdTech, dataParent: unknown): Promise<void> {
    const files = this.value();
    return new Promise((res, rej) => {
      if (!files) {
        return res();
      }

      return from(files)
        .pipe(
          mergeMap(file => this.uploadFile(file, idTechParent, dataParent).done),
          take(files.length) //Force automatic unsubscribe after all documents
        )
        .toPromise()
        .catch(err => rej(err))
        .finally(() => {
          this.reset();
          return res();
        });
    });
  }
}
