/* eslint-disable no-console */
import { Component } from '@angular/core';
import { SettlementModel } from '@instruments/models/settlement.model';
import { InstrumentFormService } from '@instruments/services';
import { SettlementHistoryModalsService } from '@instruments/services/modals/settlement-history-modals.service';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-settlement-history-action',
  templateUrl: './settlement-history-action.component.html'
})
export class SettlementHistoryActionComponent {
  params: ICellRendererParams | undefined;

  constructor(private readonly settlementHistoryModalsService: SettlementHistoryModalsService, private readonly instrumentFormService: InstrumentFormService) {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  public openHistory(): void {
    const row = this.params?.data as SettlementModel;
    const api = this.params?.api;
    console.log('Row => ', row.id);
    console.log('api => ', api);
    const modal = this.settlementHistoryModalsService.openSettlementHistoryModal(row);
    modal.result.then((res: any) => {
      if (res.reason !== 'confirm') {
        return;
      }
    });
  }
  public get hasHistory(): boolean {
    const settlement = this.params?.data as SettlementModel;
    return !!(settlement?.settlementStatusHistory && settlement?.settlementStatusHistory?.length > 0);
  }
}
