import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { InstrumentModel } from '@instruments/models/instrument.model';
import { routeToInstrument, routeToProgramme, routeToTranche } from '@instruments/routes';
import { InstrumentFormService, InstrumentModalsService } from '@instruments/services';
import { isNumber } from '@ngneat/spectator';
import { toIpaTypeModelFromTypedDataModel, TypedDataModel } from '@shared/models';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { InstrumentService } from '@shared/services/instrument.service';
import { PermissionService } from '@shared/services/permission.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Forms } from '@shared/utils';
import { LocationService } from '@shared/services/location.service';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-save-cancel-button-bar',
  templateUrl: './save-cancel-button-bar.component.html',
  exportAs: 'saveAndClose'
})
export class SaveCancelButtonBarComponent implements OnInit, OnDestroy {
  @Input() cameFromProgramPage = false;
  @Input() programmeId: number | undefined;
  private readonly shutdown$ = new Subject<void>();
  private canEditInstrument = false;
  private couponFrequencies: TypedDataModel[] = [];
  public disableButton = false;

  constructor(
    private readonly router: Router,
    private readonly activeRoute: ActivatedRoute,
    public readonly instrumentFormService: InstrumentFormService,
    private readonly instrumentService: InstrumentService,
    private readonly permissionService: PermissionService,
    private readonly toastService: ToastManagerService,
    private readonly modalsService: InstrumentModalsService,
    private readonly locationService: LocationService
  ) {}
  public get isEditMode(): boolean {
    return this.instrumentFormService.formMode === 'edit';
  }

  public get isAddMode(): boolean {
    return this.instrumentFormService.formMode === 'add';
  }

  public get isConsultMode(): boolean {
    return this.instrumentFormService.formMode === 'consult';
  }

  public get instrument(): Nullable<InstrumentModel> {
    return this.instrumentFormService.rawValue();
  }

  public get canCancel(): boolean {
    return this.canEditInstrument && !this.isConsultMode;
  }
  public get canSave(): boolean {
    return this.canEditInstrument && !this.isConsultMode;
  }

  ngOnInit(): void {
    this.couponFrequencies = this.activeRoute.snapshot.data['couponFrequencies'] as TypedDataModel[];
    this.permissionService.canEditInstrument$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canEditInstrument: boolean) => {
        this.canEditInstrument = canEditInstrument;
      }
    });
  }

  ngOnDestroy(): void {
    this.shutdown$.next();
    this.shutdown$.complete();
  }

  private saveInstrument(instrument: InstrumentModel) {
    this.disableButton = true;
    const zeroCoupon = toIpaTypeModelFromTypedDataModel(this.couponFrequencies.find(q => q.valueDescription === INSTRUMENT_CONSTANTS.Types.couponFrequencies.NOFREQUENCY));
    if (!zeroCoupon) {
      this.disableButton = false;
      return;
    }

    const createOrUpdate = (this.isAddMode ? this.instrumentService.create : this.instrumentService.update).bind(this.instrumentService);
    createOrUpdate(instrument, zeroCoupon).subscribe(instrumentId => {
      this.disableButton = false;
      const id = instrumentId || this.instrument.id;
      if (!id || !isNumber(id)) {
        throw new Error('Missing instrument id after update or create');
      }
      this.router.navigate(routeToTranche(id, instrument.selectedTrancheNumber, 'consult'));
    });
  }

  private saveTranche(instrument: InstrumentModel) {
    this.disableButton = true;
    if (instrument?.id && instrument.selectedTrancheNumber) {
      this.instrumentService.updateTranche(instrument.id, instrument.selectedTrancheNumber, instrument).subscribe(_ => {
        this.disableButton = false;
        this.router.navigate(routeToTranche(instrument.id, instrument.selectedTrancheNumber, 'consult'));
      });
    }
  }

  onSaveClick(): void {
    if (this.isConsultMode) {
      return;
    }

    this.instrumentFormService.updateValueAndValidity({ emitEvent: false });
    const instrument = this.instrumentFormService.value();

    if (!instrument) {
      this.toastService.toastWarning('toasts.instruments.invalid-form.title', 'toasts.instruments.invalid-form.message');
      return;
    }

    const modal = this.modalsService.openSaveModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      if (!instrument?.selectedTrancheNumber) {
        return;
      }
      const trancheNumber = instrument.selectedTrancheNumber;
      if (trancheNumber === 1) {
        //piwik Event Tracking
        (window as any)._paq.push(['trackEvent', PIWIK_CONST.eventCategories.Instruments, PIWIK_CONST.eventActions.updateInstrument]);
        this.saveInstrument(instrument);
      } else {
        //piwik Event Tracking
        (window as any)._paq.push(['trackEvent', PIWIK_CONST.eventCategories.Instruments, PIWIK_CONST.eventActions.updateTranche]);
        this.saveTranche(instrument);
      }
    });
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Instruments, PIWIK_CONST.eventActions.onBackClicked])
  goBack(): void {
    if (this.locationService.navgationHistories.length >= 1) {
      this.router.navigateByUrl(this.locationService.getBackUrl());
      return;
    } else if (this.cameFromProgramPage) {
      this.router.navigate(routeToProgramme(this.programmeId));
      return;
    }
    const instrumentId = this.instrument?.id || null;
    if (!instrumentId || this.instrumentFormService.formMode === Forms.PageConsult) {
      this.router.navigate(routeToInstrument());
      return;
    }
    this.router.navigate(routeToInstrument(instrumentId));
  }

  onCancelClick(): void {
    if (this.instrumentFormService.dirty && this.instrumentFormService.touched) {
      const modal = this.modalsService.openCancelModal();
      modal.result.then((res: DialogResult) => {
        if (res !== 'confirm') {
          return;
        }
        this.goBack();
      });
      return;
    }

    this.goBack();
  }
}
