<div class="d-flex flex-row justify-content-end mb-0 mt-2" style="white-space: nowrap;" *ngIf="!isConsultMode">
  <button *ngIf="canCancel" (click)="onCancelClick()" (keyup.enter)="onCancelClick()"
    class="btn btn-default btn-md btn-outline-default" translate>common-buttons.cancel
  </button>
  <button *ngIf="canSave" (click)="onSaveClick()" [disabled]="disableButton" (keyup.enter)="onSaveClick()" class="btn btn-md btn-primary btn-icon-start ms-3"
    translate>
    <em class="icon me-1">done</em>
    common-buttons.save
  </button>
</div>
