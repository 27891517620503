<div class="d-flex flex-row flex-wrap justify-content-between align-items-center flex-wrap-reverse">
  <div>
    <ul class="nav nav-underline">
      <li class="nav-item" *ngFor="let tranche of filteredTranches" [class.selectable]="canChangeTranche">
        <span [class.disabled]="!canChangeTranche" class="nav-link" (click)="onTrancheSelected(tranche)"
          [class.active]="tab === 'Tranche' && tranche.trancheNumber === selectedTrancheNumber">
          <span class="me-2 title-case" translate
            [translateParams]="{ trancheNumber: tranche.trancheNumber, name: name }"
            *ngIf="tranche.trancheNumber > 1">instruments.tranches.name</span>
          <span class="me-2 title-case" [translateParams]="tranche" translate
            *ngIf="tranche.trancheNumber === 1">instruments.tranches.tranche1</span>
          <app-status size="sm" [status]="tranche.status"> </app-status>
        </span>
      </li>
      <li class="nav-item" *ngIf="showMoreButton">
        <button class="btn btn-md btn-flat-default" (click)="onShowingMoreTranches()">
          <span translate> instruments.buttons.more-tranche </span>
        </button>
      </li>
      <li class="nav-item" [class.selectable]="isConsult">
        <span class="nav-link" (click)="onHistorySelected()" [class.disabled]="!isConsult"
          [class.active]="tab === 'TrancheHistory'">
          <span translate [translateParams]="{ name: name }">
            <em class="icon icon-md">history</em>
            instruments.tabs.tranche-history</span>
        </span>
      </li>
      <li class="nav-item" *ngIf="isSgLuxCommonDepOrSafeKeeper()" [class.selectable]="isConsult">
        <span class="nav-link" (click)="onCommonDepositorySelected()" [class.disabled]="!isConsult"
          [class.active]="tab === 'commonDepository'">
          <span translate>
            <em class="icon icon-md">account_balance</em>
            instruments.tabs.commonDepository</span>
        </span>
      </li>
    </ul>
  </div>
  <div *ngIf="tab == 'Tranche'" >
    <div class="d-flex ">
      <button *ngIf="canIssue" (click)="onUpdateStatusInstrumentClick('ISSUE')"
        (keyup.enter)="onUpdateStatusInstrumentClick('ISSUE')" class="btn btn-md btn-outline-success m-2">
        <span class="title-case">{{ issueLabel | translate }}</span>
      </button>
      <button *ngIf="canCancel" class="btn btn-md btn-outline-danger m-2"
        (click)="onUpdateStatusInstrumentClick('CANCEL')" (keyup.enter)="onUpdateStatusInstrumentClick('CANCEL')">
        <span class="title-case">{{ cancelLabel | translate }}</span>
      </button>
      <button *ngIf="canReopen" class="btn btn-md btn-outline-warning m-2"
        (click)="onUpdateStatusInstrumentClick('REOPEN')" (keyup.enter)="onUpdateStatusInstrumentClick('REOPEN')">
        <span class="title-case" translate>instruments.buttons.reopen</span>
      </button>
      <button *ngIf="canFang" class="btn btn-md btn-outline-danger m-2" (click)="onMergeTrancheClick('FONG')">
        <span class="title-case" translate>instruments.buttons.merge.tranche</span>
      </button>
      <button *ngIf="canUpload" (click)="onUpload()" class="btn btn-default btn-md btn-outline-default btn-icon-start m-2" [disabled]="disableUpload">
        <em class="icon">upload</em>
        <span class="title-case">Upload document</span>
      </button>
      <button *ngIf="canModify" (click)="onEditClick()" class="btn btn-default btn-md btn-outline-default btn-icon-start m-2" [disabled]="disableModify">
        <em class="icon">mode_edit</em>
        <span class="title-case">{{ 'instruments.buttons.modify.tranche' | translate }}</span>
      </button>
    
      <button *ngIf="canCreate" (click)="onCreateTrancheClick()" class="btn btn-md btn-primary btn-icon-start m-2"
        [disabled]="!isConsult">
        <em class="icon">add</em>
        <span class="title-case">{{ createLabel | translate }}</span>
      </button>
    </div>
  </div>
</div>
