import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DragNDropDirective } from './directives';
import { DocumentFormsListComponent, DocumentsListComponent, ProgressBarComponent, UploadingFilesComponent, DocumentFormItemComponent } from './components';
import { SharedModule } from '@shared/shared.module';
import { LocalCustomFormModule } from '@shared/modules/forms/local-custom-form.module';
import { UploadDocumentsModule } from '@instruments/components/upload-documents/upload-documents.module';

const components = [DocumentFormItemComponent, DocumentFormsListComponent, DocumentsListComponent, ProgressBarComponent, UploadingFilesComponent];

const directives = [DragNDropDirective];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, SharedModule, LocalCustomFormModule, UploadDocumentsModule],
  declarations: [...components, ...directives],
  exports: [DocumentFormsListComponent, DocumentsListComponent, UploadingFilesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DocumentsModule {}
