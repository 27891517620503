/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateStatusRequestModel } from '@instruments/models';
import { InstrumentModel } from '@instruments/models/instrument.model';
import { TrancheModel } from '@instruments/models/tranche.model';
import { routeToInstrument, routeToTranche } from '@instruments/routes';
import { InstrumentFormService, InstrumentModalsService, TrancheDisplayService, TrancheModalsService } from '@instruments/services';
import { INSTRUMENT_ACTIONS, ISSUE_OR_CANCEL } from '@shared/models';
import { InstrumentService } from '@shared/services/instrument.service';
import { PermissionService } from '@shared/services/permission.service';
import { of, Subject, Subscription } from 'rxjs';
import { first, mergeMap, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IpaTypeModelRules as R } from '@instruments/rules';
import { INSTRUMENT_CONSTANTS } from '@instruments/configs/instrument-constants';
import { FongeTrancheModalsService } from '@instruments/services/modals/fonge-tranche-modals.service';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';

@Component({
  selector: 'app-tranche-selector',
  templateUrl: './tranche-selector.component.html',
  styleUrls: ['./tranche-selector.component.scss']
})
export class TrancheSelectorComponent implements OnInit, OnDestroy {
  @Input() public disableUpload = false;
  @Input() disableModify = false;

  private readonly DefaultNbTrancheDisplayed = 5;
  public tranches: TrancheModel[] = [];
  public selectedTranche: TrancheModel | null = null;
  private readonly subscriptions: Subscription[] = [];
  private canEditInstrument = false;
  public filteredTranches: TrancheModel[] = [];
  private nbTranchesDisplayed = this.DefaultNbTrancheDisplayed;
  public showMoreButton = false;
  // instrumentStatus: IpaTypeModel | null | undefined;
  public tab: InstrumentSelectorTabType = 'Tranche';
  public upto?: boolean | null;
  private canReadCommonDep!: boolean;
  public readonly shutdown$ = new Subject<void>();
  public canUpload!: boolean;

  @Output() public handleUpload = new EventEmitter<boolean>();
  @Output() public handleModify = new EventEmitter<boolean>();

  constructor(
    private readonly router: Router,
    private readonly instrumentService: InstrumentService,
    private readonly trancheModalsService: TrancheModalsService,
    private readonly fongeTrancheModalsService: FongeTrancheModalsService,
    public readonly instrumentFormService: InstrumentFormService,
    private readonly trancheDisplayService: TrancheDisplayService,
    private readonly permissionService: PermissionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: I18nUtilsService,
    private readonly modalsService: InstrumentModalsService
  ) {}

  public get selectedTrancheNumber(): number {
    return this.selectedTranche?.trancheNumber || 1;
  }
  public get isEditMode(): boolean {
    return this.instrumentFormService.formMode === 'edit';
  }
  public get isConsult(): boolean {
    return this.instrumentFormService.formMode === 'consult';
  }
  public get canChangeTranche(): boolean {
    return this.isConsult && this.canEditInstrument;
  }
  public get isCurrentTrancheInitialized(): boolean {
    return R.isInitialized(this.selectedTranche?.status);
  }

  public get isCurrentTrancheLive(): boolean {
    return R.isLive(this.selectedTranche?.status);
  }
  public get isCurrentTrancheCancelled(): boolean {
    return R.isCancelled(this.selectedTranche?.status);
  }
  public get isCurrentTrancheClosed(): boolean {
    return R.isClosed(this.selectedTranche?.status);
  }

  public get isCurrentTrancheTefraD(): boolean {
    return R.isTefraD(this.instrument?.physicalForm);
  }

  public get isCurrentTrancheClosedOrCancled(): boolean {
    return R.isClosed(this.selectedTranche?.status) || R.isCancelled(this.selectedTranche?.status);
  }

  public get canCancel(): boolean {
    // tranche 1 is not live && not closed && not cancelledisCurrentTrancheInitialized
    return this.isConsult && this.canEditInstrument && !this.isCurrentTrancheCancelled && !this.isCurrentTrancheLive && !this.isCurrentTrancheClosed;
  }
  public get canReopen(): boolean {
    return this.isConsult && this.canEditInstrument && this.isInstrumentClosed;
  }
  public get canIssue(): boolean {
    return this.isConsult && this.canEditInstrument && this.isCurrentTrancheInitialized;
  }

  public get canCreate(): boolean {
    return this.isConsult && this.canEditInstrument && !this.isInstrumentCancelled && !this.isInstrumentClosed;
  }

  public get canModify(): boolean {
    return this.isConsult && this.canEditInstrument && !this.isCurrentTrancheClosedOrCancled && !this.isInstrumentCancelled && !this.isInstrumentClosed;
  }
  public get isInstrumentClosed(): boolean {
    return this.instrument.status?.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.CLOSED;
  }
  public get isInstrumentInitialized(): boolean {
    return this.instrument.status?.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.INITIALIZED;
  }
  public get isInstrumentLive(): boolean {
    return this.instrument.status?.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.LIVE;
  }
  public get isInstrumentCancelled(): boolean {
    return this.instrument.status?.valueDescription === INSTRUMENT_CONSTANTS.Types.statuses.CANCELLED;
  }

  public get canFang(): boolean {
    return this.isConsult && this.canEditInstrument && this.isCurrentTrancheLive && this.isCurrentTrancheTefraD && this.selectedTrancheNumber > 1;
  }
  public get cancreateTranche(): boolean {
    return !this.isInstrumentCancelled && !this.isInstrumentClosed;
  }
  public get instrument(): Nullable<InstrumentModel> {
    return this.instrumentFormService.rawValue();
  }

  public get cancelLabel(): string {
    if (this.selectedTrancheNumber === 1) {
      return 'instruments.buttons.cancel.instrument';
    }
    return 'instruments.buttons.cancel.tranche';
  }

  public get issueLabel(): string {
    if (this.selectedTrancheNumber === 1) {
      return 'instruments.buttons.issue.instrument';
    }
    return 'instruments.buttons.issue.tranche';
  }

  public get fongeLabel(): string {
    return 'instruments.buttons.fonge.tranche';
  }

  public onShowingMoreTranches(): void {
    this.nbTranchesDisplayed = Math.min(this.tranches.length, this.nbTranchesDisplayed + this.DefaultNbTrancheDisplayed);
    this.filteredTranches = this.tranches.slice(0, this.nbTranchesDisplayed);
    this.showMoreButton = this.nbTranchesDisplayed < this.tranches.length;
  }

  ngOnInit(): void {
    if (this.isEditMode) {
      this.disableUpload = true;
    }
    this.permissionService.userCanUpload$
      .pipe(
        tap(userCanUpload => (this.canUpload = userCanUpload)),
        takeUntil(this.shutdown$)
      )
      .subscribe();
    this.upto = this.instrumentFormService.rawValue().upto;
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        if ((params['tab'] as InstrumentSelectorTabType) === 'commonDepository') {
          this.onCommonDepositorySelected();
        }
      })
    );
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(arg => {
        this.instrumentFormService.setSelectedTranche(+arg.trancheNumber);
      })
    );
    this.subscriptions.push(
      this.instrumentFormService.currentTranche$.subscribe(tranche => {
        this.selectedTranche = tranche || null;
        this.nbTranchesDisplayed = Math.max(this.nbTranchesDisplayed, this.selectedTrancheNumber);
        this.filteredTranches = this.tranches.slice(0, this.nbTranchesDisplayed);
        this.showMoreButton = this.nbTranchesDisplayed < this.tranches.length;
      }),
      this.instrumentFormService.tranches$.subscribe(tranches => {
        this.tranches = (tranches || []).filter(
          tranche => tranche.trancheNumber === 1 || (tranche.status?.valueDescription !== INSTRUMENT_CONSTANTS.Types.statuses.CANCELLED && tranche.status?.valueDescription !== INSTRUMENT_CONSTANTS.Types.statuses.CLOSED)
        );
        this.nbTranchesDisplayed = Math.max(Math.min(this.tranches.length, this.DefaultNbTrancheDisplayed), this.selectedTrancheNumber);
        this.filteredTranches = this.tranches.slice(0, this.nbTranchesDisplayed);
        this.showMoreButton = this.nbTranchesDisplayed < this.tranches.length;
      }),
      this.permissionService.canEditInstrument$.subscribe({
        next: (canEditInstrument: boolean) => {
          this.canEditInstrument = canEditInstrument;
        }
      }),
      this.permissionService.canReadCommonDep$.subscribe({
        next: (canReadCommonDep: boolean) => {
          this.canReadCommonDep = canReadCommonDep;
        }
      }),
      ...this.trancheDisplayService.Compile().map(o => o.subscribe())
    );
  }

  public get createLabel(): string {
    if (this.upto === true) {
      return 'instruments.buttons.createIncrease';
    }
    return 'instruments.buttons.createTranche';
  }

  public get name(): string {
    if (this.upto === true) {
      return this.translateService.getI18nValue('tranche-selector.increase');
    }
    return this.translateService.getI18nValue('tranche-selector.tranche');
  }

  public isSgLuxCommonDepOrSafeKeeper(): boolean {
    return (
      this.canReadCommonDep &&
      (this.instrument.commonDepositary?.name === INSTRUMENT_CONSTANTS.Parties.commonDepositary.SOCIETEGENERALELUXEMBOURG || this.instrument.commonSafeKeeper?.name === INSTRUMENT_CONSTANTS.Parties.commonDepositary.SOCIETEGENERALELUXEMBOURG)
    );
  }

  public onTrancheSelected(tranche: TrancheModel): void {
    if (this.canChangeTranche) {
      this.tab = 'Tranche';
      this.instrumentFormService.selectTabType('Tranche');
      this.instrumentFormService.setSelectedTranche(tranche.trancheNumber);
    }
  }
  public onHistorySelected(): void {
    this.tab = 'TrancheHistory';
    this.instrumentFormService.selectTabType('TrancheHistory');
  }

  public onCommonDepositorySelected(): void {
    this.tab = 'commonDepository';
    this.instrumentFormService.selectTabType('commonDepository');
  }

  onCreateTrancheClick(): void {
    const modal = this.trancheModalsService.openCreateTrancheModal(this.instrumentFormService.value());
    modal.result.then((res: any) => {
      if (res.reason !== 'confirm') {
        return;
      }
      this.createTranche(res?.tranche);
    });
  }

  onMergeTrancheClick(action: ISSUE_OR_CANCEL): void {
    const dataType = this.selectedTrancheNumber === 1 ? 'instrument' : 'tranche';
    const modal = this.fongeTrancheModalsService.openFongeTrancheModal(this.instrumentFormService.value());
    modal.result.then((res: any) => {
      if (res.reason !== 'confirm') {
        return;
      }
      const fongeTrancheModel = res.fonge;
      const modal = this.trancheModalsService.openConfirmChangeStatusModal(action, dataType);
      modal.result.then((res: DialogResult) => {
        if (res !== 'confirm') {
          return;
        }

        const params: UpdateStatusRequestModel = {
          ipaCode: this.instrument.id,
          trancheNumber: this.selectedTrancheNumber === 1 ? null : this.selectedTrancheNumber,
          certifiedNominal: fongeTrancheModel?.certifiedNominal || null,
          partiallyCertified: fongeTrancheModel?.partiallyCertified
        };
        this.subscriptions.push(
          this.instrumentService
            .updateStatus(action, params)
            .pipe(
              switchMap(_ => {
                if (this.instrument?.id) {
                  return this.instrumentService.getInstrumentById(this.instrument.id).pipe(first());
                }
                return of(null);
              })
            )
            .subscribe(i => {
              this.instrumentFormService.setTranchesInformations(i?.tranches || [], this.selectedTrancheNumber);
              this.router.navigate(routeToInstrument(i?.id, 'consult'));
            })
        );
      });
    });
  }

  public onUpdateStatusInstrumentClick(action: INSTRUMENT_ACTIONS): void {
    const dataType = this.selectedTrancheNumber === 1 ? 'instrument' : 'tranche';
    const modal = this.trancheModalsService.openConfirmChangeStatusModal(action, dataType);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      const params: UpdateStatusRequestModel = {
        ipaCode: this.instrument.id,
        trancheNumber: this.selectedTrancheNumber === 1 ? null : this.selectedTrancheNumber
      };
      this.instrumentService
        .updateStatus(action, params)
        .pipe(first())
        .pipe(takeUntil(this.shutdown$))
        .subscribe(_ => {
          if (this.instrument?.id) {
            this.instrumentService
              .getInstrumentById(this.instrument.id)
              .pipe(first())
              .pipe(takeUntil(this.shutdown$))
              .subscribe(i => {
                this.instrumentFormService.setTranchesInformations(i?.tranches || [], this.selectedTrancheNumber);
                if (action === 'REOPEN') {
                  //redirect to tranche 1 after reopen
                  this.router.navigate(routeToTranche(i?.id, 1, 'consult'));
                } else {
                  this.router.navigate(routeToInstrument(i?.id, 'consult'));
                }
              });
          }
        });
    });
  }

  private createTranche(trancheModel: TrancheModel | null) {
    if (trancheModel && this.instrument?.id) {
      this.instrumentService
        .createTranche(this.instrument.id, trancheModel, this.instrument.upto || false)
        .pipe(
          mergeMap(ids => {
            if (!ids) {
              return of(null);
            }
            return this.instrumentService.getInstrumentById(ids.instrumentId).pipe(map(instrument => ({ instrument, trancheNumber: ids.trancheNumber })));
          }),
          first()
        )
        .subscribe(result => {
          if (!result) {
            return;
          }
          this.instrumentFormService.setInitialValue(result.instrument);
          this.instrumentFormService.setSelectedTranche(result.trancheNumber);
          this.instrumentFormService.setTranches(result.instrument?.tranches || []);
          this.router.navigate(routeToInstrument(result.instrument?.id, 'consult'));
        });
    }
  }

  onEditClick(): void {
    this.handleModify.emit(false);
    this.router.navigate(routeToTranche(this.instrument.id, this.selectedTrancheNumber, 'edit'));
    this.disableUpload = true;
  }
  onUpload() {
    this.handleUpload.emit(true);
    this.disableModify = true;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(o => o.unsubscribe());
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}
