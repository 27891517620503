import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CharacteristicModel, InstrumentModel, TrancheModel } from '@instruments/models';
import { CharacteristicFormService, InstrumentFormService } from '@instruments/services';
import { ClearingSystemsConfigsService } from '@instruments/services/clearing-systems-configs.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { PartyModel, TYPED_DATA_TYPE, ProgrammeInformation, ROLES_TYPE, TYPE_FILTER_TYPE, ProgrammeModel, TypedDataModel } from '@shared/models';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { PartyService } from '@shared/services/party.service';
import { isXSIsin, notEmpty } from '@utils/utility-functions';
import { combineLatest, interval, Observable, Subscription } from 'rxjs';
import { debounce, map, startWith, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-instrument-characteristics',
  styleUrls: ['./instrument-characteristics.component.scss'],
  templateUrl: './instrument-characteristics.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: CharacteristicFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.instrument.forms.instrumentCharacteristics' }
  ]
})
export class InstrumentCharacteristicsComponent extends BaseFormComponent<CharacteristicModel, CharacteristicFormService> implements OnInit, OnDestroy {
  @Input() programme: ProgrammeModel | null = null;

  public filteredBdrIds: string[] = [];
  public LABEL_CATEGORY: TYPED_DATA_TYPE = 'LABEL_CATEGORY';
  public LEGAL_DESK_ISSUER_COUNTRY: TYPED_DATA_TYPE = 'LEGAL_DESK_ISSUER_COUNTRY';
  public INSTRUMENT_TYPE: TYPED_DATA_TYPE = 'INSTRUMENT_TYPE';
  public CLEARING_SYSTEM: ROLES_TYPE = 'CLEARING_SYSTEM';
  public INSTRUMENT: TYPE_FILTER_TYPE = 'INSTRUMENT';
  private readonly subscriptions: Subscription[] = [];
  private programmeId: number | null = null;
  private selectedTranche: TrancheModel | undefined;
  clearingSystems: PartyModel[] = [];
  instrumentTypes: TypedDataModel[] = [];
  private parties: [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][] | undefined;
  constructor(
    formService: CharacteristicFormService,
    private readonly instrumentFormService: InstrumentFormService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly partyService: PartyService,

    private readonly clearingSystemsConfigsService: ClearingSystemsConfigsService
  ) {
    super(formService, 'instruments.instrument.placeholder.instrumentCharacteristics');
  }
  ngOnInit(): void {
    this.parties = this.activatedRoute.snapshot.data['parties'] as [TYPE_FILTER_TYPE, ROLES_TYPE, PartyModel[]][];
    this.clearingSystems = this.getParties('INSTRUMENT', 'CLEARING_SYSTEM');
    const values = this.activatedRoute.snapshot.data['instrument'] as [InstrumentModel | null, ProgrammeModel | null];

    this.programme = values ? values[1] : null;

    this.subscriptions.push(
      this.instrumentFormService.currentTranche$.subscribe(tranche => {
        this.selectedTranche = tranche || null;
      })
    );

    this.subscriptions.push(
      combineLatest([this.activatedRoute.data.pipe(startWith(...[])), this.activatedRoute.params.pipe(startWith(...[]))]).subscribe(([routeData, routeParams]) => {
        const values = routeData['instrument'] as [InstrumentModel | null, ProgrammeModel | null];

        this.programme = values ? values[1] : null;
        const instrument = values ? values[0] : null;
        this.programmeId = instrument?.internalProgrammeNumber || routeParams.internalProgrammeNumber || null;
        if (instrument) {
          this.loadLeiInformations(instrument);
        }
      })
    );
    this.subscribeToIsinAndinstrumentType();
  }

  private subscribeToIsinAndinstrumentType(): void {
    if (this.selectedTranche?.trancheNumber === 1 || this.formMode === 'add') {
      this.subscriptions.push(
        this.formService
          .valueChanges(['isinReference', 'instrumentType'])
          .pipe(
            switchMap(changes => {
              return this.clearingSystemsConfigsService.getConfig(changes.isinReference, changes.instrumentType?.valueDescription);
            }),
            debounce(_ => interval(1000))
          )
          .subscribe(configs => {
            const isin = this.formService.rawValue('isinReference');
            if (configs.some(_ => true)) {
              const partyValues = this.clearingSystems.filter(q => configs.some(c => c.bdrId === q.bdrId));
              if (partyValues.some(_ => true)) {
                this.formService.patch('clearingSystems', [...partyValues]);
                this.formService.lockFields('clearingSystems');
              }
            } else if (isin && isXSIsin(isin)) {
              this.setCommonCode(isin);
            } else {
              this.formService.unlockFields('clearingSystems');
            }
          })
      );
    }
  }
  private getParties(filter: TYPE_FILTER_TYPE, role: ROLES_TYPE): PartyModel[] {
    return (this.parties?.find(q => q[0] === filter && q[1] === role) ?? [filter, role, []])[2] ?? [];
  }

  private loadLeiInformations(instrument: InstrumentModel) {
    this.formService.loadingLeiInformationSubject.next(true);
    if (instrument?.issuerLeiCode) {
      this.subscriptions.push(
        this.partyService
          .getIssuerBdrDataFromLeiCode(instrument.issuerLeiCode)
          .pipe(
            map(progInfo => {
              if (!progInfo) {
                return null;
              }
              const bdrId = `${progInfo.leiCode} - ${progInfo.fullLegalName} - BDR ${progInfo.bdrId}`;
              return {
                bdrId,
                issuer: {
                  bdrId: progInfo.bdrId,
                  leiCode: progInfo.leiCode,
                  name: progInfo.fullLegalName
                },
                programmeInformation: progInfo
              };
            })
          )
          .subscribe(res => {
            this.formService.loadingLeiInformationSubject.next(false);
            if (res) {
              this.onProgrammeInformationSelected(res?.programmeInformation);
            }
          })
      );
    }
  }
  private setCommonCode(isinReference: string): void {
    if (this.formMode !== 'consult') {
      if (!isinReference) {
        this.formService.patch('commonCode', null);
        return;
      }
      if (!isXSIsin(isinReference)) {
        return;
      }

      const updatedCommonCode = isXSIsin(isinReference) ? isinReference.substring(2, 11) : 0;
      this.formService.patch('commonCode', updatedCommonCode || null);
    }
  }

  onIssuerSelected(issuer: PartyModel | null): void {
    this.formService.patch('issuer', issuer);
  }
  onProgrammeInformationSelected(programmeInformation: ProgrammeInformation | null): void {
    this.formService.setProgrammeInformation(programmeInformation, !this.programmeId, this.selectedTranche?.trancheNumber || 1);
  }

  public get hasIcsdProgrammeNumber(): boolean {
    return notEmpty(this.formService.rawValue('icsdProgrammeNumber'));
  }
  public get hasInternalProgrammeNumber(): boolean {
    return notEmpty(this.formService.rawValue('internalProgrammeNumber'));
  }

  public get programmeInformation$(): Observable<ProgrammeInformation | null> {
    return this.formService.programmeInformation$;
  }

  public get loadingLeiInformation$(): Observable<boolean> {
    return this.formService.loadingLeiInformation$;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
