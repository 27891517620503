<div class="row justify-content-between">
    <div class="align-items-stretch" [ngClass]="hide?'col-md-11':'col-md-8'">
        <div class="border p-4  bg-lvl1">
            <ng-content select="[left-side]"></ng-content>
        </div>
    </div>
    <div class="d-inline-flex align-items-stretch " [ngClass]="hide?'col-md-1':'col-md-4'">
        <div class="border pt-4 pe-4 ps-2 bg-lvl1" [ngClass]="!hide?'flex-grow-1':''">
            <div class="d-flex flex-row align-items-start  " [ngClass]="hide?'justify-content-center':''">
                <button [ngClass]="!hide?'me-3':''" class="btn btn-flat-secondary " type="button"
                    (click)="changeHide()">
                    <em class="icon" *ngIf="hide"> keyboard_arrow_left </em>
                    <em *ngIf="!hide" class="icon"> keyboard_arrow_right </em>
                </button>
                <div class="mt-3 flex-grow-1">
                    <ng-content *ngIf="!hide" select="[right-side]"></ng-content>
                </div>
            </div>
            <div class="flex-row justify-content-center ms-2">
                <em class="icon icon-lg text-secondary " *ngIf="hide"> description </em>
            </div>
        </div>
    </div>
</div>