/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*  eslint-disable @typescript-eslint/no-explicit-any */

import { Subscription } from 'rxjs';

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DocumentService } from '../../services/document.service';
import { DocumentFileModel } from '@documents/models/document.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { DocumentFormService } from '@documents/services';
import { DialogModalComponent } from '@shared/modules/modals/components';
import { TypedDataModel } from '@shared/models';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: DocumentFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'documents.document.forms' }
  ]
})
export class DocumentsListComponent {
  @Input() canDeleteFile = false;
  @Input() isEditMode = false;
  @Input() isLoading = false;
  @Input() showTitle = true;
  @Input() documents: DocumentFileModel[] = [];
  @Output() public updatedoc = new EventEmitter<boolean>();
  public disableUpload = true;
  subscriptions: Subscription[] = [];
  selectedDocuments: DocumentFileModel[] = [];

  switchs: Record<string, boolean | null> = {
    switchDateDown: true,
    switchNameDown: null,
    switchTypeDown: null,
    switchConfidentialityLevelDown: null
  };

  private couponFrequencies: TypedDataModel[] = [];

  constructor(private readonly documentService: DocumentService, private readonly modalService: NgbModal) {}

  private openDeleteDocumentModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.deleteFile';
    dialog.dialogBody = 'modals.contents.deleteFile';
    return modal;
  }

  private openDeleteSelectedDocumentsModal(): NgbModalRef {
    const modal = this.modalService.open(DialogModalComponent);
    const dialog: DialogModalComponent = modal.componentInstance;
    dialog.dialogTitle = 'modals.titles.deleteSelectedFile';
    dialog.dialogBody = 'modals.contents.deleteSelectedFile';
    return modal;
  }

  private deleteSingleDocument(document: DocumentFileModel): void {
    this.documentService.deleteDocument(document).then(isDeleted => {
      if (isDeleted) {
        this.documents = this.documents.filter(d => d !== document);
      }
    });
  }

  deleteDocument(document: DocumentFileModel): void {
    const modal = this.openDeleteDocumentModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }

      this.deleteSingleDocument(document);
    });
  }

  deleteSelectedDocuments(): void {
    const modal = this.openDeleteSelectedDocumentsModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      this.selectedDocuments.forEach(d => this.deleteSingleDocument(d));
    });
  }

  downloadDocument(document: DocumentFileModel) {
    this.documentService.downloadDocumentContent(document);
  }

  openDocument(document: DocumentFileModel) {
    this.documentService.openDocumentContent(document);
  }

  selectDocument(document: DocumentFileModel): void {
    const index = this.selectedDocuments.indexOf(document);
    if (index === -1) {
      this.selectedDocuments.push(document);
    } else {
      this.selectedDocuments.splice(index, 1);
    }
  }
  getIsUpload(val: boolean) {
    this.updatedoc.emit(val);
  }
  selectAllDocuments(): void {
    if (this.selectedDocuments.length === this.documents.length) {
      this.selectedDocuments = [];
    } else {
      this.selectedDocuments = [...this.documents];
    }
  }
  sortChanged(keyClicked: string, isDate: boolean, fields: string[]) {
    this.toggleSwitchs(keyClicked);
    this.sortList(!!this.switchs[keyClicked], fields);
  }

  sortList(isDsc: boolean, fields: string[]): void {
    if (!this.documents) {
      return;
    }
    this.documents.sort(this.sortByDate(fields));

    if (isDsc) {
      this.documents.reverse();
    }
  }

  sortByDate(fields: string[]) {
    return (a: any, b: any) => {
      const dateA = new Date(this.getValueByPath(a, fields));
      const dateB = new Date(this.getValueByPath(b, fields));
      if (dateA.getTime() < dateB.getTime()) {
        return -1;
      } else if (dateA.getTime() > dateB.getTime()) {
        return 1;
      }
      return 0;
    };
  }
  sortBy(fields: string[]) {
    return (a: any, b: any) => {
      const constA = this.getValueByPath(a, fields);
      const constB = this.getValueByPath(b, fields);
      if (constA.toUpperCase() < constB.toUpperCase()) {
        return -1;
      } else if (constA > constB) {
        return 1;
      }
      return 0;
    };
  }

  getValueByPath(obj: any, path: string[]): any {
    path.forEach(element => (obj = obj[element]));
    return obj;
  }

  toggleSwitchs(keyNotChange: any) {
    Object.keys(this.switchs).forEach(k => {
      if (keyNotChange !== k) {
        this.switchs[k] = null;
      } else if (this.switchs[k] === null) {
        this.switchs[k] = false;
      } else {
        this.switchs[k] = !this.switchs[k];
      }
    });
  }
}
